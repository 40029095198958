import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";

// component
import LightDescription from "../../templates/text/LightDescription.js";

// images
import PresaleIconPath from "../../assets/images/icons/presale_icon.png";

// styles
const Container = styled.section`
	background-color: #101012;
	padding: 6rem 0;

	@media (max-width: 600px) {
		padding: 4rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 3rem 0;
	}
`;

const Wrapper = styled.div`
	width: 90%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}

	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

const GridContainer = styled.div`
	width: 100%;
`;

const GridWrapper = styled.div`
	width: 100%;
`;

const GridBox = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	gap: 1rem;
	margin: 0.5rem 0;

	@media (max-width: 600px) {
		margin: 1rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		gap: 0.5rem;
	}
`;

const IconBox = styled.div``;

const Icon = styled.img`
	width: 2.5rem;
	object-ft: contain;

	@media (max-width: 600px) {
	}

	@media (min-width: 601px) and (max-width: 1024px) {
		width: 1.5rem;
	}
`;

const GridTextBox = styled.div`
	width: 100%;
`;

const GridTitleText = styled.p`
	color: #fff;
	font-size: 1.2rem;
	font-weight: 300;
	font-family: "PlayBold";
	opacity: 0.9;
	margin-bottom: 0.5rem;

	@media (max-width: 600px) {
		font-size: 1.3em;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.8rem;
		margin-bottom: 0.2rem;
	}
`;

const GridValueText = styled.p`
	color: #fff;
	font-size: 1rem;
	font-weight: 300;
	font-family: "Montserrat";
	opacity: 0.9;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 30ch;

	@media (max-width: 600px) {
		font-size: 1em;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
		max-width: 20ch;
	}
`;

const PreSaleContainer = styled.div`
	@media (max-width: 600px) {
		margin-top: 2rem;
	}
`;

const PreSaleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;

	@media (max-width: 600px) {
		gap: 1.5rem;
	}
`;

const PreSaleBox = styled.div`
	text-align: center;
	padding: 4rem;
	border-radius: 1rem;
	background: linear-gradient(180deg, #ffa722 0%, #ff6b00 100%);

	@media (max-width: 600px) {
		padding: 3rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 2rem;
	}
`;

const BannerText = styled.div`
	width: 40%;
	margin: 4rem auto 0 auto;
	text-align: center;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

export default function PreSale({bnbRaised}) {
	const firstGridData = [
		{
			title: "Token Name",
			value: "Fafy Token",
			// subPoints: [
			// 	{subValue: "Token symbol: FAFY" }, // Replace with actual symbol if different
			// 	{subValue: "Token Network: BSC" } // Replace with actual network if different
			// ]
		},
		{ title: "Token Symbol ", value: "FAFY" },
		{ title: "Total Supply ", value: "100,000,000,000 FAFY (100%)" },
	];

	const secondGridData = [
		{ title: "Token Network", value: "BSC" },
		{
			title: "Contract address ",
			value: "0xd3d8cb32623ba286ae920838080f2cd09848392f",
			url: "https://bscscan.com/address/0xd3d8cb32623ba286ae920838080f2cd09848392f",
		},
	];

	return (
		<Container id='presale'>
			<Wrapper>
				<GridContainer>
					<Grid container justifyContent='space-between' alignItems='center'>
						<Grid item xs={12} sm={4} md={3.8}>
							<GridWrapper>
								{firstGridData?.map((item, index) => (
									<GridBox key={index}>
										<IconBox>
											<Icon
												src={PresaleIconPath}
												alt='Pre Sale Icon'
												loading='lazy'
												height='auto'
												width='auto'
											/>
										</IconBox>

										<GridTextBox>
											<GridTitleText>{item.title}</GridTitleText>
											<GridValueText>{item.value}</GridValueText>
											{item.subPoints?.map((subItem, subIndex) => (
												<div key={subIndex}>
													<GridTitleText>{subItem.subTitle}</GridTitleText>
													<GridValueText>{subItem.subValue}</GridValueText>
												</div>
											))}
										</GridTextBox>
									</GridBox>
								))}
							</GridWrapper>
						</Grid>

						<Grid item xs={12} sm={4} md={3.8}>
							<GridWrapper>
								{secondGridData?.map((item, index) => (
									<GridBox key={index}>
										<IconBox>
											<Icon
												src={PresaleIconPath}
												alt='Pre Sale Icon'
												loading='lazy'
												height='auto'
												width='auto'
											/>
										</IconBox>
										<GridTextBox>
											<GridTitleText>{item.title}</GridTitleText>
											<GridValueText>{item.value}</GridValueText>
										</GridTextBox>
									</GridBox>
								))}
							</GridWrapper>
						</Grid>

						<Grid item xs={12} sm={4} md={4}>
							<PreSaleContainer>
								<PreSaleWrapper>
									<PreSaleBox>
										<GridTitleText>While others ponder the future, Fafy Token pioneers it - seize the opportunity today and own a stake in the future of fair and lucrative content creation.</GridTitleText>
									</PreSaleBox>
									{/* <PreSaleBox>
										<GridTitleText>BNB Raised is {bnbRaised}</GridTitleText>
									</PreSaleBox> */}
								</PreSaleWrapper>
							</PreSaleContainer>
						</Grid>
					</Grid>
				</GridContainer>

				{/* <BannerText>
					<LightDescription>
						Unsold tokens from previous rounds will be moved into the next round. The token sale will
						automatically go to the next round when tokens are sold out in the prior round.
					</LightDescription>
				</BannerText> */}
			</Wrapper>
		</Container>
	);
}
