import React from "react";
import styled from "styled-components";

const Button = styled.button`
	background: var(--orange);
	color: #131313;
	font-size: 1.1rem;
	font-weight: 700;
	font-family: "PlayBold";
	padding: 0.85rem 2rem;
	border-radius: 0.3rem;
	text-transform: uppercase;
	transition: all 0.25s ease-out;

	&:hover {
		color: #fff;
		transform: scale(1.03);
		background: #ff6b00;
	}
	@media (max-width: 600px) {
		font-size: 1rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
		padding: 0.6rem 1.5rem;
	}
`;

export default function OrangeButton({ children }) {
	return <Button>{children}</Button>;
}
