import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Grid, Divider } from "@mui/material";

// component
import LightDescription from "../templates/text/LightDescription.js";

// images
import FooterLogoPath from "../assets/images/fafy_logo.png";
import LinkedInPath from "../assets/images/icons/github.png";
import Discord from "../assets/images/icons/discord.png";
import InstaPath from "../assets/images/icons/ins.png";
import FBPath from "../assets/images/icons/fb.png";
import XPath from "../assets/images/icons/twitter.png";
import Youtube from "../assets/images/icons/youtube.png";
import Telegram from "../assets/images/icons/telegram.png";
import Reddit from "../assets/images/icons/reddit.png";
import { Github } from "lucide-react";

// styles
const Container = styled.section`
	background-color: #0a0a0c;
	padding: 4rem 0;

	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 2rem 0;
	}
`;

const Wrapper = styled.div`
	width: 90%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}

	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

const GridContainer = styled.div``;

const LogoContainer = styled.div`
	@media (max-width: 600px) {
		text-align: center;
	}
`;

const LogoImageBox = styled.div`
	margin-bottom: 1rem;
`;

const LogoImage = styled.img`
	width: 16rem;
	object-fit: contain;

	@media (max-width: 600px) {
		margin: auto;
	}

	@media (min-width: 601px) and (max-width: 1024px) {
		width: 10rem;
	}
`;

const LogoTextBox = styled.div`
	width: 60%;

	@media (max-width: 600px) {
		width: 100%;
	}

	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

const SocialContainer = styled.div`
	margin-top: 5rem;

	@media (max-width: 600px) {
		margin-top: 3rem;
	}
`;

const SocialWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	@media (max-width: 600px) {
		justify-content: center;
		gap: 1rem;
	}
`;

const SocialImageBox = styled.div``;

const SocialImage = styled.img`
	display: block;
	width: 2.3rem;
	object-fit: contain;
	transition: all 0.2s ease-out;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
		transform: scale(1.15);
	}

	@media (max-width: 600px) {
	}

	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

const TabContainer = styled.div`
	@media (max-width: 600px) {
		margin: 2rem 0;
	}
`;

const TabWrapper = styled.div``;

const TabBox = styled.div`
	display: flex;

	@media (max-width: 600px) {
		justify-content: center;
		text-align: center;
	}
`;

const Tabs = styled.p`
	color: #fff;
	font-size: 1rem;
	font-weight: 300;
	font-family: "Montserrat";
	opacity: 0.9;
	border-bottom: 3px solid transparent;
	transition: all 0.25s ease-out;

	&:hover {
		color: var(--orange);
		border-bottom: 3px solid var(--orange);
	}
	@media (max-width: 600px) {
		font-size: 1em;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.8rem;
	}
`;

const RightContainer = styled.div`
	padding-top: 2rem;
	text-align: center;
`;

const RightText = styled.p`
	font-family: "Montserrat";
	font-size: 0.95rem;
	color: #fff;
	letter-spacing: 1px;
	line-height: 1.5;
	opacity: 0.6;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.6rem;
	}
	@media (min-width: 1025px) and (max-width: 1439px) {
		font-size: 0.7rem;
	}
	@media (min-width: 1900px) {
		font-size: 0.9rem;
	}
`;

export default function Footer() {
	const socialMedia = [
		{ link: "https://github.com/fafytoken", image: LinkedInPath },
		{ link: "https://twitter.com/fafytoken", image: XPath },
		{ link: "https://www.youtube.com/@FafyToken", image: Youtube },
		{ link: "https://www.reddit.com/user/FafyToken/", image: Reddit },
		{ link: "https://t.me/fafytokenofficial", image: Telegram },
		{ link: "https://discord.gg/bXtTTA9H", image: Discord }
	];

	const tabLinks = [
		{ link: "/", title: "Home" },
		{ link: "#about", title: "About" },
		{ link: "#tokenomic", title: "Tokenomics" },
		{ link: "#roadmap", title: "Roadmap" },
		{ link: "#faq", title: "Faq" },
		{ link: "https://fafytoken.com/static/media/whitepaper.2e5a7e10fa3403f2a709.pdf", title: "Whitepaper" },
		{ link: "mailto:support@fafytoken.com", title: "Contact us at support@fafytoken.com" },
	];

	const [year, setYear] = useState("");

	// Retrieve Year function
	const YearHandler = () => {
		const yearValue = new Date().getFullYear();
		setYear(yearValue);
	};

	useEffect(() => {
		YearHandler();
	}, []);

	return (
		<Container>
			<Wrapper>
				<GridContainer>
					<Grid container justifyContent='space-between' spacing={0}>
						<Grid item xs={12} sm={8} md={8}>
							<LogoContainer>
								<LogoImageBox>
									<LogoImage
										src={FooterLogoPath}
										alt='FaFy Token'
										loading='lazy'
										height='auto'
										width='auto'
									/>
								</LogoImageBox>
								<LogoTextBox>
									<LightDescription>
									In a world overflowing with creativity, Fafy Token stands out as a beacon of innovation, providing an ecosystem where content creators, users, and investors come together to redefine the landscape of digital content.{" "}
									</LightDescription>
								</LogoTextBox>

								<SocialContainer>
									<SocialWrapper>
										{socialMedia?.map((item, index) => (
											<SocialImageBox key={index}>
												<a href={item.link} target='_blank'>
													<SocialImage
														src={item.image}
														alt={item.link}
														loading='lazy'
														height='auto'
														width='auto'
													/>
												</a>
											</SocialImageBox>
										))}
									</SocialWrapper>
								</SocialContainer>
							</LogoContainer>
						</Grid>

						<Grid item xs={12} sm={2} md={2}>
							<TabContainer>
								<TabWrapper>
									{tabLinks?.map((item, index) => (
										<TabBox key={index}>
											<a href={item.link} >
												<Tabs>{item.title}</Tabs>
											</a>
										</TabBox>
									))}
								</TabWrapper>
							</TabContainer>
						</Grid>
					</Grid>
				</GridContainer>

				<Divider sx={{ backgroundColor: "#fff", opacity: ".3", mt: 4 }} />

				{/* Rights */}
				<RightContainer>
					<RightText>Copyrights &copy; {year} Fafy Token</RightText>
				</RightContainer>
			</Wrapper>
		</Container>
	);
}
