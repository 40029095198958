import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";

// component
import LargeHeader from "../../templates/text/LargeHeader.js";
import LightDescription from "../../templates/text/LightDescription.js";
import OrangeButton from "../../templates/button/OrangeButton.js";

// images
import GridImagePath from "../../assets/images/token_grid_image.png";

// styles
const Container = styled.section`
  background-color: #101012;
  padding: 6rem 0;

  @media (max-width: 600px) {
    padding: 4rem 0;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    padding: 3rem 0;
  }
`;

const Wrapper = styled.div`
  width: 85%;
  margin: auto;

  @media (max-width: 600px) {
    width: 90%;
  }

  @media (min-width: 601px) and (max-width: 1024px) {
  }
`;

const GridContainer = styled.div``;

const GridImageBox = styled.div``;

const GridImage = styled.img`
  width: 27rem;
  object-fit: contain;

  @media (max-width: 600px) {
    width: 20rem;
    margin: auto;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    width: 15rem;
    margin: auto;
  }
`;

const GridTextBox = styled.div`
  @media (max-width: 600px) {
    text-align: center;
  }
`;

const GridTextHeaderBox = styled.div``;

const GridTextDescriptionBox = styled.div`
  margin: 1rem 0 2.5rem 0;
`;

const GridTextButtonBox = styled.div``;

export default function TokenSale() {
  return (
    <Container id="about">
      <Wrapper>
        <GridContainer>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <GridImageBox>
                <GridImage
                  src={GridImagePath}
                  alt="Token Sale are live"
                  loading="lazy"
                  height="auto"
                  width="auto"
                />
              </GridImageBox>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <GridTextBox>
                <GridTextHeaderBox>
                  <LargeHeader>About</LargeHeader>
                </GridTextHeaderBox>
                <GridTextDescriptionBox>
                  <LightDescription>
                    Fafy Token's journey began with a visionary group of
                    engineers and specialists who shared a common dream—to
                    pioneer a crypto project that safeguards and champions the
                    rights of content creators. Rooted in their collective
                    passion for innovation and fairness, these individuals
                    embarked on a mission to create a unique and promising
                    platform within the crypto space.
                  </LightDescription>
                </GridTextDescriptionBox>
                <GridTextDescriptionBox>
                  <LightDescription>
                    Our team brings together a wealth of expertise in blockchain
                    technology, software engineering, cybersecurity and content
                    creation. Fuelled by a deep understanding of the challenges
                    faced by creators in the digital landscape, they set out to
                    revolutionize content monetization.
                  </LightDescription>
                </GridTextDescriptionBox>
                <GridTextDescriptionBox>
                  <LightDescription>
                    Driven by the belief that every creator deserves recognition
                    and fair compensation for their contributions, our founders
                    aimed to disrupt the status quo. Their dedication to
                    protecting content creators' rights has been the cornerstone
                    of Fafy Token's inception, shaping a platform that
                    prioritizes transparency, fairness, and empowerment.
                  </LightDescription>
                </GridTextDescriptionBox>
                <GridTextDescriptionBox>
                  <LightDescription>
                    Drawing from their diverse backgrounds and expertise, the
                    founders laid the groundwork for Fafy Token- a decentralized
                    ecosystem designed to foster direct engagement between
                    creators and their audience. Their commitment to innovation,
                    paired with a strong sense of integrity, has shaped Fafy
                    Token's core values and mission.
                  </LightDescription>
                </GridTextDescriptionBox>
                <GridTextDescriptionBox>
                  <LightDescription>
                    As a team, they continue to steer Fafy Token towards its
                    vision of redefining content monetization. Their unwavering
                    dedication to empowering creators remains at the heart of
                    every decision, ensuring that Fafy Token stands as a beacon
                    of trust, fairness, and progress in the crypto-powered
                    content creation realm.
                  </LightDescription>
                </GridTextDescriptionBox>
                <GridTextButtonBox>
                  <a href="/#">
                    <OrangeButton>JOIN TOKEN SALE</OrangeButton>
                  </a>
                </GridTextButtonBox>
              </GridTextBox>
            </Grid>
          </Grid>
        </GridContainer>
      </Wrapper>
    </Container>
  );
}
