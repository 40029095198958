import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Grid, TextField, MenuItem } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

// component
import LargeHeader from "../../templates/text/LargeHeader.js";
import LightDescription from "../../templates/text/LightDescription.js";
import OrangeButton from "../../templates/button/OrangeButton.js";

// whitepaper url
import whitepaper from "../../components/ui/whitepaper.pdf";

// styles
const Container = styled.section`
	background-color: #101012;
	padding: 6rem 0;

	@media (max-width: 600px) {
		padding: 4rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 3rem 0;
	}
`;

const Wrapper = styled.div`
	width: 90%;
	margin: auto;

	@media (max-width: 600px) {
		width: 95%;
	}

	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

const GridContainer = styled.div``;

const GridTextBox = styled.div`
	@media (max-width: 600px) {
		text-align: center;
	}
`;

const GridTextHeaderBox = styled.div``;

const GridTextDescriptionBox = styled.div`
	margin: 1rem 0 2.5rem 0;
`;

const GridTextButtonBox = styled.div``;

// Swap Styling
const SwapContainer = styled.div`
	background-color: #f0f0f0;
	border-radius: 1rem;

	@media (max-width: 600px) {
		margin-top: 3rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		border-radius: 0.5rem;
	}
`;

const SwapWrapper = styled.div`
	padding: 2rem;

	@media (max-width: 600px) {
		padding: 1.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 1rem;
	}
`;

const SwapHeaderBox = styled.div``;

const SwapHeader = styled.p`
	color: #131313;
	font-size: 1.4rem;
	font-weight: 700;
	font-family: "PlayBold";
	text-transform: capitalize;

	@media (max-width: 600px) {
		font-size: 1rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.9rem;
	}
`;

const SwapLabel = styled.p`
	color: #131313;
	font-size: 1.15rem;
	font-weight: 700;
	font-family: "PlayBold";
	text-transform: capitalize;
	margin: 0;

	@media (max-width: 600px) {
		font-size: 0.85rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.58rem;
	}
`;

const SwapLabel1 = styled.p`
	color: #131313;
	font-size: 1.6rem;
	font-weight: bold; // Make the text bold
	font-family: "PlayBold";
	text-transform: capitalize;
	margin: 0;
	text-align: center; // Center the text

	@media (max-width: 600px) {
		font-size: 0.75rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.58rem;
	}
`;

const DateContainer = styled.div``;

const DateWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 0.5rem;
`;

const DateBox = styled.div`
	width: 100%;
	background-color: #fff;
	text-align: center;
	padding: 1rem 1.5rem;
	border-radius: 1rem;
	box-shadow: 2.90977px 4.84962px 24.2481px 0px rgba(0, 0, 0, 0.07);

	@media (max-width: 600px) {
		padding: 0.7rem 0.3rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 0.6rem 0rem;
	}
`;

const DateTitle = styled.p`
	color: #131313;
	font-size: 2.5rem;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 1.5rem;
	font-family: "PlayBold";
	text-transform: capitalize;

	@media (max-width: 600px) {
		font-size: 1.6rem;
		margin-bottom: 0.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		margin-bottom: 0.5rem;
		font-size: 1.2rem;
	}
`;

const DateLabel = styled.p`
	color: #131313;
	font-size: 1.1rem;
	font-weight: 700;
	line-height: 0;
	font-family: "PlayBold";
	text-transform: capitalize;

	@media (max-width: 600px) {
		font-size: 0.75rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.6rem;
	}
`;

const DateDivider = styled.p`
	color: #131313;
	font-size: 3.5rem;
	font-weight: 700;
	line-height: 0;
	font-family: "Montserrat";
	text-transform: capitalize;

	@media (max-width: 600px) {
		display: none;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		display: none;
	}
`;

// Progress Styling
const ProgressContainer = styled.div`
	margin: 2rem 0;
	margin-top: 0;

	@media (max-width: 600px) {
		margin: 1rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		margin: 1rem 0;
	}
`;

const ProgressWrapper = styled.div``;

const ProgressLabelContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 1.5rem 0;

	@media (max-width: 600px) {
		margin: 1rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		margin: 0.8rem 0;
	}
`;

const PresaleHeader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: -2px; /* Adjust the margin as necessary */
	/* other styles */
`;

const ProgressLabelWrapper = styled.div`
	position: relative;
	z-index: 0;
	background-color: #fff;
	text-align: center;
	padding: 0.5rem 1.5rem;
	border-radius: 0.5rem;
	box-shadow: 2.90977px 4.84962px 24.2481px 0px rgba(0, 0, 0, 0.07);

	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 0.3rem 1rem;
	}
`;

const ProgressLabelWrapperOverLay = styled.div`
	background-color: #fff;
	position: absolute;
	z-index: -1;
	height: 2rem;
	width: 2rem;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	clip-path: polygon(50% 100%, 0 0, 100% 0);
	box-shadow: 2.90977px 4.84962px 24.2481px 0px rgba(0, 0, 0, 0.07);

	@media (max-width: 600px) {
		transform: translate(-50%, 40%);
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		transform: translate(-50%, 40%);
	}
`;

const ProgressLabel = styled.div`
	color: #131313;
	font-size: 1.1rem;
	font-weight: 700;

	font-family: "PlayBold";
	text-transform: capitalize;

	@media (max-width: 600px) {
		font-size: 0.75rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.5rem;
	}
`;

const ProgressBox = styled.div``;

const ProgressBar = styled.progress`
	/* height: 7px; */
	text-align: center;
	color: white;
	background-image: green;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
`;

// Swap Detail Styling
const DetailContainer = styled.div``;

const DetailWrapper = styled.div``;

const DetailBox = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.2rem 0;

	@media (min-width: 601px) and (max-width: 1024px) {
		gap: 0.3rem;
		padding: 0.1rem 0;
	}
`;

const DetailLabel = styled.p`
	color: #131313;
	font-size: 1.1rem;
	font-weight: 400;
	font-family: "PlayReg";
	text-transform: capitalize;
	margin: 0;

	@media (max-width: 600px) {
		font-size: 0.6rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.55rem;
	}
`;

// Swap Button
const SwapButtonBox = styled.div`
	margin-top: 1rem;
`;

const SwapButton = styled.button`
	background-color: var(--orange);
	color: #131313;
	width: 100%;
	font-size: 1.3rem;
	font-weight: 900;
	font-family: "PlayBold";
	padding: 0.9rem 0;
	border-radius: 0.5rem;
	text-transform: uppercase;
	transition: all 0.25s ease-out;

	&:hover {
		color: #fff;
		transform: scale(1.02);
		background: #ff6b00;
	}
	@media (max-width: 600px) {
		font-size: 1rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
		padding: 0.6rem 1.5rem;
	}
`;

// Swap Menu Styling
const SwapMenuContainer = styled.div`
	margin-top: 2rem;

	@media (max-width: 600px) {
		margin-top: 1rem;
	}
`;

const SwapMenuWrapper = styled.div`
	display: flex;
	align-items: stretch;
	gap: 1rem;

	@media (max-width: 600px) {
		flex-direction: column;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		flex-direction: column;
	}
`;

const SwapMenuBox = styled.div`
	height: 100%;
	width: 100%;
	background-color: #d9d9d970;
	border: 1px solid #d9d9d9;
	padding: 1rem;
	border-radius: 0.5rem;

	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 0.5rem;
	}
`;

const SwapMenuOverRide = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-size: 1.6rem;
	font-weight: 900;
	font-family: "PlayBold";
	margin: 0.3rem 0;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.2rem;
	}
`;

// Swap Balance Styling
const SwapBalanceContainer = styled.div``;

const SwapBalanceWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const LargeHeader1 = styled.h1`
	font-size: 2.8rem; // Adjust the size as needed
	font-weight: 600;
	font-family: PlayBold;
	// ... other styles
`;

const CopyReferralButton = styled(SwapButton)`
	background-color: #f6b432; // Adjust the color as needed
	&:hover {
		background: darkgreen; // Adjust the hover color as needed
	}
`;

// const VideoPreviewBox = styled.div`
//   margin: 2.5rem 0;
//   padding: 0.5rem;
//   border: 1px solid #ccc; // subtle border
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // soft shadow for depth
//   border-radius: 8px; // rounded corners
//   overflow: hidden; // ensure everything stays within the rounded corners
//   position: relative; // for positioning the play icon
//   cursor: pointer; // change cursor to indicate it's clickable

//   &:hover {
//     box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); // deeper shadow on hover
//   }

//   iframe {
//     width: 100%;
//     height: 400px; // Adjust as needed
//     border: none; // Remove iframe border
//   }

//   @media (max-width: 600px) {
//     height: 400px;
//   }

//   @media (min-width: 601px) and (max-width: 1024px) {
//     height: 180px;
//   }
// `;

const VideoPreviewBox = styled.div`
	margin: 2.5rem auto; // Added 'auto' to center it horizontally
	padding: 0.4rem;
	border: 1px solid #ccc;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	width: 580px;

	&:hover {
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
	}

	iframe {
		width: 100%;
		height: 270px; // Make the height responsive
		border: none;
	}

	@media (max-width: 600px) {
		margin: 2.9rem auto; // Ensure top and bottom margin is the same and auto for left and right
		height: auto; // Adjust height to be responsive
		width: auto;
	}
`;

export default function Hero({
	handleBuy,
	buyLoader,
	currency,
	setCurrency,
	currencyValue,
	setCurrencyValue,
	currencyBalance,
	userAddress,
	bnbRaised,
}) {
	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const [bnbPrice, setBnbPrice] = useState(0);
	const [tokenAmount, setTokenAmount] = useState(0);

	const processBar = Number((bnbRaised / 400) * 100).toFixed(2);
	console.log(bnbRaised);

	// Swap details
	const swapDetail = [
		{
			label: "Presale Price",
			value: " | 0.00006$",
		},
		{
			label: "Min Transaction Amount:",
			value: "0.02 BNB",
		},
		{
			label: "Maximum amount:",
			value: "10 BNB",
		},
		{
			label: "SoftCap:",
			value: "20 BNB",
		},
	];
	// Coin details
	const coinDetail = [
		{
			value: `BNB`,
			label: "BNB",
			image: "https://bscscan.com/token/images/bnbchain2_32.png",
		},
		// {
		// 	value: `ETH`,
		// 	label: "ETH",
		// 	image: "https://bscscan.com/token/images/bnbchain2_32.png",
		// },
	];

	const deadline = "January, 1, 2024";

	const getTime = () => {
		const time = Date.parse(deadline) - Date.now();

		setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
		setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
		setMinutes(Math.floor((time / 1000 / 60) % 60));
		setSeconds(Math.floor((time / 1000) % 60));
	};

	const handChange = () => {
		console.log("BNB");
		// change this once more currency are added, by default BNB is the allowing supported currency at the moment
		setCurrency("BNB");
	};

	const calculateTokenAmount = (bnbAmount) => {
		const tokenPriceUSD = 0.00007;
		const tokenPerBnb = bnbPrice / tokenPriceUSD;
		const tokens = bnbAmount * tokenPerBnb;
		setTokenAmount(tokens);
	};

	useEffect(() => {
		// const interval = setInterval(() => getTime(deadline), 1000);
		// return () => clearInterval(interval);
		fetch("https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT")
			.then((response) => response.json())
			.then((data) => setBnbPrice(data.price))
			.catch((error) => console.error("Error fetching BNB price:", error));
	}, []);

	// const handleCopyReferralLink = () => {
	// 	if (userAddress) {
	// 		const referralLink = `http://localhost:3000/?referral=${userAddress}`;
	// 		navigator.clipboard.writeText(referralLink)
	// 			.then(() => {
	// 				// Optional: Display a confirmation message to the user
	// 				toast.success("Referral address copied!");
	// 			})
	// 			.catch(err => {
	// 				// Optional: Handle any errors
	// 				console.error('Error copying text: ', err);
	// 			});
	// 	}
	// };

	const handleCopyReferralLink = () => {
		// Check if the userAddress is null or an empty string
		if (!userAddress) {
			toast.error("Please connect your wallet to copy the referral link.");
			return; // Exit the function early if no userAddress is found
		}

		// If userAddress exists, proceed to copy the referral link
		const referralLink = `https://fafytoken.com/?referral=${userAddress}`;
		navigator.clipboard
			.writeText(referralLink)
			.then(() => {
				// Display a confirmation message to the user
				toast.success("Referral link copied to clipboard!");
			})
			.catch((err) => {
				// Handle any errors
				console.error("Error copying text: ", err);
				toast.error("Failed to copy the referral link.");
			});
	};

	return (
		<Container>
			<Wrapper>
				<GridContainer>
					<Grid
						container
						alignItems="center"
						justifyContent="space-between"
						spacing={2}
					>
						{/* Header, Description and whitepaper button */}
						<Grid item xs={12} sm={6} md={5}>
							<GridTextBox>
								<GridTextHeaderBox>
									<LargeHeader1>What is Fafy Token</LargeHeader1>
								</GridTextHeaderBox>
								<GridTextDescriptionBox>
									<LightDescription>
										FafyToken is a crypto-powered content monetization project
										that leverages blockchain technology and cryptocurrencies to
										revolutionize the way content creators earn money for their
										work.
									</LightDescription>
								</GridTextDescriptionBox>
								<GridTextButtonBox>
									<a target="_blank" href={whitepaper} rel="noreferrer">
										<OrangeButton>FAFY WHITEPAPER</OrangeButton>
									</a>
								</GridTextButtonBox>
							</GridTextBox>
						</Grid>

						{/* Swap */}
						{/* <VideoPreviewBox>
							<iframe
							src="https://www.youtube.com/embed/Wo82rh889GY" // Replace with the desired video link
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							></iframe>
						</VideoPreviewBox> */}
						<Grid item xs={12} sm={5.5} md={5.5}>
							<SwapContainer>
								<SwapWrapper>
									<PresaleHeader>
										<SwapHeader>Fafy Token Last Presale</SwapHeader>
									</PresaleHeader>
									<DetailContainer>
										<DetailWrapper>
											{swapDetail?.map((item, index) => (
												<DetailBox key={index}>
													<SwapLabel>
														{item.label} {item.value}
													</SwapLabel>
												</DetailBox>
											))}
										</DetailWrapper>
									</DetailContainer>
									<SwapMenuContainer>
										<SwapMenuWrapper className="pb-2 flex flex-col gap-1">
											<div className="flex justify-between items-center gap-2">
												<div className="font-semibold">
													{Number(bnbRaised).toFixed(4)} / 400 BNB
												</div>
												<div className="font-semibold">{processBar}%</div>
											</div>
											<div className="w-full h-3 bg-yellow-700 rounded-full">
												<div
													className="h-3 bg-yellow-400 rounded-full animate-process-animation process_back"
													style={{
														width: processBar + "%",
													}}
												></div>
											</div>
										</SwapMenuWrapper>
										<SwapMenuWrapper>
											<SwapMenuBox>
												<DetailLabel>Currency</DetailLabel>
												<DetailBox>
													<img
														src="https://bscscan.com/token/images/bnbchain2_32.png"
														alt="BNB Token"
														style={{
															width: "25px",
															height: "25px",
															marginRight: "0.5rem",
														}}
													/>
													<SwapLabel>BNB</SwapLabel>
												</DetailBox>
											</SwapMenuBox>
											<SwapMenuBox>
												<TextField
													label="Amount"
													variant="outlined"
													type="tel"
													fullWidth
													value={currencyValue}
													onChange={(e) => {
														setCurrencyValue(e.target.value);
														calculateTokenAmount(e.target.value);
													}}
													sx={{
														"& fieldset": { border: "none" },
													}}
													inputProps={{
														style: {
															fontFamily: "PlayBold",
															fontSize: 25,
															p: 0,
															height: "100%",
														},
													}}
													InputLabelProps={{
														style: {
															fontFamily: "Montserrat",
															fontSize: { xs: 18, sm: 12, md: 18 },
														},
													}}
												/>
												<div>
													{currencyValue > 0 && (
														<div style={{ marginTop: "10px" }}>
															You will receive approximately:{" "}
															{tokenAmount.toFixed(2)} tokens
														</div>
													)}
												</div>
											</SwapMenuBox>
										</SwapMenuWrapper>
									</SwapMenuContainer>
									<SwapButtonBox>
										<SwapButton onClick={handleBuy}>Buy Now</SwapButton>
									</SwapButtonBox>
									{/* <SwapButtonBox>
									<CopyReferralButton onClick={handleCopyReferralLink}>Copy Referral Link</CopyReferralButton>
									</SwapButtonBox> */}
								</SwapWrapper>
							</SwapContainer>
						</Grid>
					</Grid>
				</GridContainer>
			</Wrapper>
		</Container>
	);
}
