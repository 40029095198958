import React, { useState } from "react";
import styled from "styled-components";
import { Grid, Dialog } from "@mui/material";

// component
import MediumHeader from "../../templates/text/MediumHeader.js";
import LightDescription from "../../templates/text/LightDescription.js";

// images
import Feature1 from "../../assets/images/icons/feature1.png";
import Feature2 from "../../assets/images/icons/feature2.png";
import Feature3 from "../../assets/images/icons/feature3.png";
import Feature4 from "../../assets/images/icons/feature4.png";
import Feature5 from "../../assets/images/icons/feature5.png";
import Feature6 from "../../assets/images/icons/feature6.png";

// styles
const Container = styled.section`
  background-color: #101012;
  padding: 4rem 0;
`;

const Wrapper = styled.div`
  width: 90%;
  margin: auto;

  @media (max-width: 600px) {
    width: 90%;
  }

  @media (min-width: 601px) and (max-width: 1024px) {
  }
`;

const HeaderBox = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`;

const GridContainer = styled.div`
  width: 100%;
`;

const GridWrapper = styled.div`
  width: 100%;
`;

const GridBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem 0;

  @media (max-width: 600px) {
    margin: 1rem 0;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    gap: 0.5rem;
  }
`;

const IconBox = styled.div``;

const Icon = styled.img`
  width: 3.5rem;
  object-ft: contain;

  @media (max-width: 600px) {
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    width: 2rem;
  }
`;

const GridTextBox = styled.div`
  width: 100%;
`;

const GridTitleText = styled.p`
  color: #fff;
  font-size: 1.4rem;
  font-weight: 300;
  font-family: "PlayBold";
  opacity: 0.9;
  margin-bottom: 0.5rem;

  @media (max-width: 600px) {
    font-size: 1.3em;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
  }
`;

const GridValueText = styled.p`
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  font-family: "Montserrat";
  opacity: 0.9;

  @media (max-width: 600px) {
    font-size: 1em;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 0.7rem;
  }
`;

const MessageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: #2b2015;
  border-radius: 0.5rem;
  text-align: center;
  display: grid;
  place-items: center;

  @media (max-width: 600px) {
    margin-top: 2rem;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
  }
`;

const MessageWrapper = styled.div`
  padding: 2rem;

  @media (min-width: 601px) and (max-width: 1024px) {
    padding: 1rem;
  }
`;

const MessageTitle = styled.p`
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  font-family: "PlayBold";
  line-height: 1.2;
  text-transform: capitalize;

  @media (max-width: 600px) {
    font-size: 2.5rem;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;

const MessageButtonBox = styled.div`
  margin-top: 4rem;
`;

const VideoPreviewBox = styled.div`
  margin: 2.5rem 0;
  padding: 0.5rem;
  border: 1px solid #ccc; // subtle border
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // soft shadow for depth
  border-radius: 8px; // rounded corners
  overflow: hidden; // ensure everything stays within the rounded corners
  position: relative; // for positioning the play icon
  cursor: pointer; // change cursor to indicate it's clickable

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); // deeper shadow on hover
  }

  iframe {
    width: 100%;
    height: 400px; // Adjust as needed
    border: none; // Remove iframe border
  }

  @media (max-width: 600px) {
    height: 400px;
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    height: 180px;
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; // Remove iframe border
`;

const PlayIconOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3rem; // Adjust size as needed
  // use an icon from your project's icon set
`;

const MessageButton = styled.button`
  background-color: var(--orange);
  color: #131313;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
  font-family: "PlayBold";
  padding: 0.9rem 0;
  border-radius: 0.3rem;
  text-transform: uppercase;
  transition: all 0.25s ease-out;

  &:hover {
    color: #fff;
    transform: scale(1.03);
    background: #ff6b00;
  }
  @media (max-width: 600px) {
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 0.7rem;
    padding: 0.6rem 1.5rem;
  }
`;

const VideoBox = styled.div`
  overflow: hidden;
  position: relative;

  @media (max-width: 600px) {
    width: 100vw;
    padding-top: 56.25%;
  }
`;

const Video = styled.iframe`
  height: 30rem;
  width: 60rem;

  @media (max-width: 600px) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    height: 20rem;
    width: 30rem;
  }
`;

export default function FeatureSection() {
  const [show, setShow] = useState(false);

  const firstGridData = [
    {
      title: "Empowering Content Creators",
      image: Feature1,
      value:
        "At the heart of Fafy Token is a commitment to empowering content creators. We understand the challenges they face in today’s digital landscape – from fair compensation to content ownership. With our blockchain-based platform, we offer transparent and automated reward-sharing through smart contracts, ensuring creators receive their fair share.",
    },
    {
      title: "Variety of Monetization Options",
      image: Feature2,
      value:
        "We believe in giving creators the freedom to choose how they monetize their content. Whether it’s through pay-per-view, subscriptions, tips, or the creation of non-fungible tokens (NFTs), Fafy Token provides a variety of monetization options to suit every creator’s style.",
    },
    {
      title: "User-Centric Experience",
      image: Feature3,
      value:
        "For our users, we’ve created an immersive and user-friendly experience. Discovering quality content has never been easier with our advanced recommendation engine. Engage with your favorite creators, participate in live events, and be part of a community that shares your passions.",
    },
  ];

  const secondGridData = [
    {
      title: "Realizing the Power of Blockchain",
      image: Feature4,
      value:
        "Built on blockchain technology, Fafy Token ensures transparency, security, and data integrity. Our decentralized system guarantees that your data and earnings are yours and yours alone. No more intermediaries; just you, your content, and your audience.",
    },
    {
      title: "Tokenization of Content",
      image: Feature5,
      value:
        "Content creators tokenize their work by creating digital tokens (non-fungible tokens or NFTs) that represent ownership or access to their content. These tokens can be unique, representing specific pieces of content, or fungible, representing a share of the creator’s work as a whole.",
    },
    {
      title: "Reward Sharing and Royalties",
      image: Feature6,
      value:
        "Creators can specify how the reward from token sales is distributed. They might choose to allocate a certain percentage of earnings to token holders, offering them a share of future income generated from their content..",
    },
  ];

  const handleClose = () => {
    setShow(false);
  };

  // https://www.youtube.com/watch?v=Wo82rh889GY

  return (
    <Container>
      <Wrapper>
        <HeaderBox>
          <MediumHeader>What makes Fafy Token unique</MediumHeader>
        </HeaderBox>

        <GridContainer>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={4} md={4}>
              <GridWrapper>
                {firstGridData?.map((item, index) => (
                  <GridBox key={index}>
                    <IconBox>
                      <Icon
                        src={item.image}
                        alt="Pre Sale Icon"
                        loading="lazy"
                        height="auto"
                        width="auto"
                      />
                    </IconBox>

                    <GridTextBox>
                      <GridTitleText>{item.title}</GridTitleText>
                      <GridValueText>{item.value}</GridValueText>
                    </GridTextBox>
                  </GridBox>
                ))}
              </GridWrapper>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <GridWrapper>
                {secondGridData?.map((item, index) => (
                  <GridBox key={index}>
                    <IconBox>
                      <Icon
                        src={item.image}
                        alt="Pre Sale Icon"
                        loading="lazy"
                        height="auto"
                        width="auto"
                      />
                    </IconBox>
                    <GridTextBox>
                      <GridTitleText>{item.title}</GridTitleText>
                      <GridValueText>{item.value}</GridValueText>
                    </GridTextBox>
                  </GridBox>
                ))}
              </GridWrapper>
            </Grid>

            {/* <Grid item xs={12} sm={3.5} md={3.5}>
              <MessageContainer>
                <MessageWrapper>
                  <MessageTitle>Future of content creation</MessageTitle>
                  <VideoPreviewBox>
                    <iframe
                      src="https://www.youtube.com/embed/Wo82rh889GY" 
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </VideoPreviewBox>
                </MessageWrapper>
              </MessageContainer>
            </Grid> */}
          </Grid>
        </GridContainer>
      </Wrapper>

      <Dialog onClose={handleClose} open={show} maxWidth="xl">
        <VideoBox>
          <Video
            height="auto"
            width="auto"
            src={`https://www.youtube.com/embed/Wo82rh889GY`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </VideoBox>
      </Dialog>
    </Container>
  );
}
