import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";

// component
import MediumHeader from "../../templates/text/MediumHeader.js";

// images
import PresaleIconPath from "../../assets/images/icons/presale_icon.png";

// styles
const Container = styled.section`
	background-color: #101012;
	padding: 6rem 0;

	@media (max-width: 600px) {
		padding: 4rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 3rem 0;
	}
`;

const SectionHeadline = styled.h2`
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1rem; // Adjust the margin as needed for your design

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 1.4rem;
  }
`;


const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}

	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

const HeaderBox = styled.div`
	text-align: center;
	margin-bottom: 4rem;
`;

const GridContainer = styled.div`
	background-color: #2b2015;
	border-radius: 1rem;
	padding: 1rem;
	margin: 2.5rem 0;
	overflow: hidden;

	@media (min-width: 601px) and (max-width: 1024px) {
		margin: 1.5rem 0;
	}
`;

const GridTextWrapper = styled.div`
	padding: 1rem 0;

	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 0;
	}
`;

const GridTextBox = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const GridText = styled.p`
	color: #fff;
	font-size: 1.3rem;
	font-weight: 400;
	font-family: "Montserrat";
	margin: 0;

	@media (max-width: 600px) {
		font-size: 1em;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
	}
`;

const GridNumberBox = styled.div`
	position: relative;
	z-index: 0;
	height: 100%;
	width: 100%;
`;

const GridNumberText = styled.p`
	position: absolute;
	z-index: 1;
	/* bottom: -120%; */
	left: 0;
	transform: translate(10%, -20%);
	color: #fff;
	font-size: 15rem;
	font-weight: 500;
	font-family: "PlayReg";
	letter-spacing: 0;
	opacity: 0.3;

	@media (max-width: 600px) {
		font-size: 10em;
		top: 0;
		right: 0;
		transform: translate(50%, 10%);
		opacity: 0.15;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 8rem;
		transform: translate(0%, -15%);
	}
`;

const Icon = styled.img`
	width: 1rem;
	object-ft: contain;

	@media (max-width: 600px) {
	}

	@media (min-width: 601px) and (max-width: 1024px) {
		width: 1.5rem;
	}
`;

export default function RoadMap() {
	const roadMap = [
		{
			number: "01",
			alignment: "row",
			headline: "2023 Q2 - Foundation and Development",
			points: [
				{ title: "Market Research and Analysis" },
				{ title: "Conceptualization and Whitepaper" },
				{ title: "Team Formation and Partnerships" },
				{ title: "Token Design and Smart Contract Development" },
			],
		},
		{
			number: "02",
			alignment: "row-reverse",
			headline: "2023 Q3 - Platform Development",
			points: [
				{ title: "Blockchain Infrastructure Setup" },
				{ title: "Core Platform Development" },
				{ title: "NFT Integration and Marketplace Development" },
				{ title: "Testing and Iteration" },
			],
		},
		{
			number: "03",
			alignment: "row",
			headline: "2023 Q4 - Launch and Growth",
			points: [
				{ title: "Website Launch" },
				{ title: "Community Building and Engagement" },
				{ title: "User Acquisition and Partnerships" },
				{ title: "Iterative Development and Feature Enhancements" },
			],
		},
		{
			number: "04",
			alignment: "row-reverse",
			headline: "2024 Q1 & Q2 - Scaling and Diversification",
			points: [
				{ title: "Scalability and Infrastructure Upgrades" },
				{ title: "Diversification and Expansion" },
				{ title: "Research and Innovation" },
				{ title: "Creators Platform Launch and Initial Content Onboarding"},
				{title: "Listing on Exchanges"},
			],
		},
	];

	return (
		<Container id='roadmap'>
			<Wrapper>
				<HeaderBox>
					<MediumHeader>Roadmap</MediumHeader>
				</HeaderBox>

				{roadMap?.map((item, index) => (
					<GridContainer key={index}>
						<Grid container justifyContent='center' direction={item.alignment}>
							<Grid item xs={12} sm={4} md={4}>
								<GridNumberBox>
									<GridNumberText>{item.number}</GridNumberText>
								</GridNumberBox>
							</Grid>
							<Grid item xs={12} sm={7} md={7}>
								<GridTextWrapper>
								<SectionHeadline>{item.headline}</SectionHeadline> {/* Add this line */}
									{item?.points.map((item, index) => (
										<GridTextBox key={index}>
											<Icon
												src={PresaleIconPath}
												alt='Pre Sale Icon'
												loading='lazy'
												height='auto'
												width='auto'
											/>
											<GridText>{item.title}</GridText>
										</GridTextBox>
									))}
								</GridTextWrapper>
							</Grid>
						</Grid>
					</GridContainer>
				))}
			</Wrapper>
		</Container>
	);
}
