import React from "react";
import styled from "styled-components";

// component
import LargeHeader from "../../templates/text/LargeHeader.js";

// styles
const Container = styled.section`
	background-color: #101012;
	padding: 8rem 0 6rem 0;

	@media (max-width: 600px) {
		padding: 6rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 3rem 0;
	}
`;

const Wrapper = styled.div`
	width: 80%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}

	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

const TextBox = styled.div`
	text-align: center;
`;

const ButtonBox = styled.div`
	text-align: center;
	margin-top: 2rem;
`;

const Button = styled.button`
	color: #fff;
	font-size: 1.1rem;
	font-weight: 600;
	/* font-family: "ANY"; */
	padding: 0.85rem 2.5rem;
	border: 3px solid var(--orange);
	border-radius: 2rem;
	transition: all 0.3s ease-in;

	&:hover {
		transform: scale(1.05);
		background-color: var(--orange);
	}
	@media (max-width: 600px) {
		font-size: 1rem;
		padding: 0.85rem 1rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
		padding: 0.6rem 1.5rem;
	}
`;

export default function MainHeader() {
	return (
		<Container>
			<Wrapper>
				<TextBox>
					<LargeHeader>
						Crypto-powered content
						<br />
						monetization
					</LargeHeader>
				</TextBox>
				<ButtonBox>
					<a target="_blank" href='https://t.me/fafytokenofficial'>
						<Button>JOIN US ON TELEGRAM</Button>
					</a>
				</ButtonBox>
			</Wrapper>
		</Container>
	);
}
