import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";

// component
import MediumHeader from "../../templates/text/MediumHeader.js";
import LightDescription from "../../templates/text/LightDescription.js";

// images
import FirstGraphPath from "../../assets/images/token1.png";
import SecondGraphPath from "../../assets/images/token2.png";

// styles
const Container = styled.section`
	background-color: #101012;
	padding: 6rem 0;

	@media (max-width: 600px) {
		padding: 4rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 3rem 0;
	}
`;

const Wrapper = styled.div`
	width: 90%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
	}
`;

const HeaderBox = styled.div`
	text-align: center;
	margin-bottom: 5rem;
`;

const DescriptionBox = styled.div``;

const GridContainer = styled.div``;

const GridWrapper = styled.div``;

const GridBox = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;

	@media (max-width: 600px) {
		flex-direction: column;
		margin: 2rem 0;
	}
`;

const GridGraphBox = styled.div``;

const GridGraphImage = styled.img`
	width: 18rem;
	object-fit: contain;

	@media (max-width: 600px) {
		width: 15rem;
	}

	@media (min-width: 601px) and (max-width: 1024px) {
		width: 12rem;
	}
`;

const ListContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;

	@media (max-width: 600px) {
		width: 100%;
		justify-content: flex-start;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		gap: 0.5rem;
	}
`;

const ListWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const ListColorBox = styled.div`
	background-color: ${(props) => props.color};
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 50%;

	aspect-ratio: 1;

	@media (min-width: 601px) and (max-width: 1024px) {
		height: 1rem;
		width: 1rem;
	}
`;

const ListTextBox = styled.div``;

export default function Tokenomic() {
	const firstOne = [
		{
			text: "50% Public Sale",
			color: "#009ACE",
		},
		{
			text: "15% Content Creator Rewards",
			color: "#9063CD",
		},
		{
			text: "5% Partnerships and Ecosystem ",
			color: "#F2CD00",
		},
		{
			text: "2.5% Transaction Fee Burning ",
			color: "#FC4C02",
		},
	];

	const secondOne = [
		{
			text: "10% User Engagement Reward",
			color: "#009ACE",
		},
		{
			text: "5% Development and Team",
			color: "#9063CD",
		},
		{
			text: "5% Airdrops and Incentives",
			color: "#F2CD00",
		},
		{
			text: "5% Reserved Fund and Contingencies",
			color: "#6DAB3C",
		},
		{
			text: "2.5% Buyback Program",
			color: "#FC4C02",
		},
	];

	return (
		<Container id='tokenomic'>
			<Wrapper>
				<HeaderBox>
					<MediumHeader>Tokenomics of Fafy Token</MediumHeader>
					<DescriptionBox>
						<LightDescription>Public Sale & Allocation</LightDescription>
					</DescriptionBox>
				</HeaderBox>

				<GridContainer>
					<Grid container justifyContent='center' spacing={2}>
						<Grid item xs={12} sm={6} md={6}>
							<GridWrapper>
								<GridBox>
									<GridGraphBox>
										<GridGraphImage
											src={FirstGraphPath}
											alt='Token Graph'
											loading='lazy'
											height='auto'
											width='auto'
										/>
									</GridGraphBox>

									<ListContainer>
										{firstOne.map((item, index) => (
											<ListWrapper key={index}>
												<ListColorBox color={item.color} />
												<ListTextBox>
													<LightDescription>{item.text}</LightDescription>
												</ListTextBox>
											</ListWrapper>
										))}
									</ListContainer>
								</GridBox>
							</GridWrapper>
						</Grid>

						<Grid item xs={12} sm={6} md={6}>
							<GridWrapper>
								<GridBox>
									<GridGraphBox>
										<GridGraphImage
											src={SecondGraphPath}
											alt='Token Graph'
											loading='lazy'
											height='auto'
											width='auto'
										/>
									</GridGraphBox>

									<ListContainer>
										{secondOne.map((item, index) => (
											<ListWrapper key={index}>
												<ListColorBox color={item.color} />
												<ListTextBox>
													<LightDescription>{item.text}</LightDescription>
												</ListTextBox>
											</ListWrapper>
										))}
									</ListContainer>
								</GridBox>
							</GridWrapper>
						</Grid>
					</Grid>
				</GridContainer>
			</Wrapper>
		</Container>
	);
}
