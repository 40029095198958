import { tv } from 'tailwind-variants';

const textTv = tv(
    {
        base: 'leading-tight p-0 text-gray-400',
        variants: {
            size: {
                sm: 'text-[12px]',
                md: 'text-base',
                lg: 'text-lg',
                xl: 'text-xl',
            },
            font: {
                sans: 'font-sans',
                serif: 'font-serif',
                mono: 'font-mono',
                display: 'font-display',
                body: 'font-body',
            },
            weight: {
                thin: 'font-thin',
                extralight: 'font-extralight',
                light: 'font-light',
                normal: 'font-normal',
                medium: 'font-medium',
                semibold: 'font-semibold',
                bold: 'font-bold',
                extrabold: 'font-extrabold',
                black: 'font-black',
            },
            color: {
                white: 'text-white',
                black: 'text-black',
                gray: 'text-gray-500',
                red: 'text-red-500',
                yellow: 'text-yellow-500',
            },
        },
        defaultVariants: {
            size: 'sm',
            font: 'display',
            weight: 'normal',
        },
    },
    {
        responsiveVariants: ['xs', 'sm', 'md', 'lg', 'xl'],
    }
);

export const Text = ({ children, className, ...rest }) => {
    return (
        <p
            className={textTv({
                className,
                ...rest,
            })}
            {...rest}
        >
            {children}
        </p>
    );
};

const headingTv = tv(
    {
        base: 'leading-tight p-0',
        variants: {
            size: {
                sm: 'text-xl',
                md: 'text-2xl',
                lg: 'text-3xl',
                xl: 'text-4xl',
            },
            font: {
                sans: 'font-sans',
                serif: 'font-serif',
                mono: 'font-mono',
                display: 'font-display',
                body: 'font-body',
            },
            weight: {
                thin: 'font-thin',
                extralight: 'font-extralight',
                light: 'font-light',
                normal: 'font-normal',
                medium: 'font-medium',
                semibold: 'font-semibold',
                bold: 'font-bold',
                extrabold: 'font-extrabold',
                black: 'font-black',
            },
            color: {
                white: 'text-white',
                black: 'text-black',
                gray: 'text-gray-500',
                red: 'text-red-500',
                yellow: 'text-yellow-500',
            },
        },
        defaultVariants: {
            size: 'xl',
            font: 'display',
            weight: 'medium',
        },
    },
    {
        responsiveVariants: ['xs', 'sm', 'md', 'lg', 'xl'],
    }
);

export const Heading = (props) => {
    return (
        <h1 className={headingTv(props)} {...props}>
            {props.children}
        </h1>
    );
};
