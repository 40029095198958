import React, { useState } from "react";
import styled from "styled-components";
import {
  SwipeableDrawer,
  List,
  ListItem,
  IconButton,
  Divider,
} from "@mui/material";

// Material icons
import CloseIcon from "@mui/icons-material/Close";

// pdf
import whitepaper from "../components/ui/whitepaper.pdf";

export default function MobileBar({ drawer, setDrawer }) {
  const drawerHandlerClose = (drawer) => {
    setDrawer(!drawer);
  };

  const tabData = [
    { name: "Home", link: "/" },
    { name: "About", link: "/#about" },
    { name: "Tokenomics", link: "/#tokenomic" },
    { name: "Roadmap", link: "/#roadmap" },
    { name: "FAQ", link: "/#faq" },
  ];

  // Styles
  const TabWrapper = styled.div`
    margin: 0.5rem 1rem;
  `;

  const TabBox = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    padding: 0.8rem 1rem;
    border-radius: 0.5rem;
    color: #fff;
    background-color: transparent;

    &:hover {
      background-color: var(--orange);
      transition: all 0.2s ease-in-out;
    }

    @media (min-width: 601px) and (max-width: 1024px) {
      padding: 0.5rem 0.2rem;
      gap: 0.4rem;
    }
  `;

  const Text = styled.p`
    font-size: 0.95rem;
    font-weight: 500;
    /* font-family: "ANY"; */

    @media (max-width: 600px) {
      font-size: 1rem;
    }
    @media (min-width: 601px) and (max-width: 1024px) {
      font-size: 0.7rem;
    }
    @media (min-width: 1025px) and (max-width: 1290px) {
      font-size: 0.85rem;
    }
  `;

  return (
    <SwipeableDrawer
      anchor="right"
      open={drawer}
      onOpen={drawerHandlerClose}
      onClose={drawerHandlerClose}
      PaperProps={{
        sx: {
          backgroundColor: "#242424",
          width: 270,
        },
      }}
    >
      <List sx={{ width: 280 }}>
        <ListItem sx={{ justifyContent: "flex-end" }}>
          <IconButton onClick={drawerHandlerClose}>
            <CloseIcon
              sx={{
                color: "var(--orange)",
                fontSize: "2rem",
                padding: ".2rem",
              }}
            />
          </IconButton>
        </ListItem>
        <Divider />

        {/* Menu */}
        {tabData?.map((item, index) => (
          <TabWrapper key={index}>
            <a href={item.link}>
              <TabBox
                onClick={() => {
                  setDrawer(false);
                }}
              >
                <Text>{item.name}</Text>
              </TabBox>
            </a>
          </TabWrapper>
        ))}
        <TabWrapper>
          <a target="_blank" href={whitepaper} rel="noreferrer">
            <TabBox>
              <Text>White Paper</Text>
            </TabBox>
          </a>
        </TabWrapper>
      </List>
    </SwipeableDrawer>
  );
}
