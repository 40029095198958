import React, { useState } from "react";
import { Text } from "../components/ui/Typography";
import whitepaper from "../components/ui/whitepaper.pdf";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { AppBar, Toolbar } from "@mui/material";

// component
import MobileBar from "../shared/MobileBar";

// images
import NavLogoPath from "../assets/images/fafy_logo.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// icon
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

// styles
const MainContainer = styled.section`
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 0.5rem 0;

  @media (max-width: 600px) {
    width: 100%;
    padding: 0.7rem 0.5rem;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    width: 90%;
    padding: 0.3rem 0;
  }
`;

const Logo = styled.a``;

const LogoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const ImageWrapper = styled.div``;

const LogoImage = styled.img`
  width: 15rem;
  height: 100%;
  object-fit: cover;

  @media (max-width: 600px) {
    width: 10rem;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    width: 10rem;
  }
`;

const DesktopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ButtonTheme = styled.button`
  font-size: 1rem;
  font-weight: 500;
  font-family: "PlayReg";
  border: none;
  border-radius: 0;
  cursor: pointer;
  letter-spacing: 0.5;
  padding: 0.2rem 0.6rem;
  text-transform: uppercase;
`;

const DesktopButton = styled(ButtonTheme)`
  background: transparent;
  opacity: 0.95;
  border-bottom: 3px solid transparent;
  transition: all 0.25s ease-out;

  &:hover {
    color: var(--orange);
    border-bottom: 3px solid var(--orange);
  }
  text-transform: none;
`;

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 2rem;

  @media (max-width: 600px) {
    margin-left: 0;
    gap: 0rem;
  }
`;

const CTAButtonSolidTheme = styled.button`
  background-color: #f2cd00;
  color: #16161a;
  /* font */
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "PlayBold";
  /* attr */
  padding: 0.7rem 2rem;
  border: none;
  border-radius: 10rem;
  transition: all 0.25s ease-out;

  &:hover {
    color: #fff;
    transform: scale(1.03);
    background: #ff6b00;
  }
  @media (max-width: 600px) {
    font-size: 0.7rem;
    padding: 0.4rem 0.4rem;
    border-radius: 1rem;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    padding: 0.5rem 1.5rem;
    font-size: 0.75rem;
  }
`;

const MobileWrapper = styled.div`
  display: block;

  @media (min-width: 601px) {
    display: none;
  }
`;

export default function NavBar({
  userAddress,
  handleWalletConnect,
  handleWalletDisconnect,
}) {
  const [drawer, setDrawer] = useState(false);

  const handleAddToken = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: "0xd3D8CB32623Ba286Ae920838080f2Cd09848392F",
              symbol: "FAFY", // Replace with your token symbol
              decimals: 18, // Replace with your token's decimals
              image: "https://fafytoken.com/fafy.png", // Replace with your token's image URL
            },
          },
        });
      } catch (error) {
        console.error("Failed to add token:", error);
      }
    } else {
      console.log("Ethereum object doesn't exist!");
    }
  };

  return (
    <MainContainer>
      <AppBar
        position="fixed"
        elevation={3}
        sx={{
          background: "#101012",
        }}
      >
        <Toolbar disableGutters>
          <Wrapper>
            <LogoWrapper>
              <Logo href="/">
                <ImageWrapper>
                  <div>
                    <LogoImage
                      src={NavLogoPath}
                      alt="Fafy Token"
                      loading="lazy"
                    />
                  </div>
                </ImageWrapper>
              </Logo>
            </LogoWrapper>

            <DesktopWrapper>
              <a href="/">
                <DesktopButton>Home</DesktopButton>
              </a>

              <a href="/#about">
                <DesktopButton>About</DesktopButton>
              </a>

              <a target="_blank" href={whitepaper} rel="noreferrer">
                <DesktopButton>White Paper</DesktopButton>
              </a>

              <a href="/#tokenomic">
                <DesktopButton>Tokenomics</DesktopButton>
              </a>

              <a href="/#roadmap">
                <DesktopButton>Roadmap</DesktopButton>
              </a>

              <a href="/#faq">
                <DesktopButton>FAQs</DesktopButton>
              </a>
            </DesktopWrapper>

            {userAddress ? (
              <CtaWrapper>
                <Text size="lg" className="m-0">
                  {userAddress.slice(0, 2)}...{userAddress.slice(-4)}
                </Text>
                <CTAButtonSolidTheme onClick={handleWalletDisconnect}>
                  Disconnect
                </CTAButtonSolidTheme>
              </CtaWrapper>
            ) : (
              <CtaWrapper>
                <CTAButtonSolidTheme onClick={handleWalletConnect}>
                  Connect Wallet
                </CTAButtonSolidTheme>
              </CtaWrapper>
            )}
            <Tooltip title="Add Fafy Token to Wallet">
              <IconButton onClick={handleAddToken}>
                <AddCircleOutlineIcon style={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>

            {/* Mboile Menu */}
            <MobileWrapper>
              <IconButton onClick={() => setDrawer(true)}>
                <MenuOpenIcon
                  sx={{ color: "var(--orange)", fontSize: "2rem" }}
                />
              </IconButton>
            </MobileWrapper>
          </Wrapper>
        </Toolbar>
      </AppBar>
      <MobileBar drawer={drawer} setDrawer={setDrawer} />
    </MainContainer>
  );
}
