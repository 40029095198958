import "./App.css";

// component
import HomeIndex from "./components/homepage/Index";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";

const mainnet = {
  chainId: 56,
  name: "Binanace",
  currency: "BSC",
  explorerUrl: "https://bscscan.com/",
  rpcUrl: "https://bsc-dataseed1.binance.org/",
};

// 3. Create modal
const metadata = {
  name: "FafyToken",
  description:
    "Join the Fafy Token ICO, the most user-friendly and innovative cryptocurrency investment opportunity. Secure, reliable, and promising blockchain technology",
  url: "http://fafytoken.netlify.app",
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    enableInjected: true,
    cacheProvider:false,
    rpcUrl: "https://bsc-dataseed1.binance.org/",
  }),
  chains: [mainnet],
  projectId: "84d771f28669a61c904aed4f92618a2c",
});

function App() {
  return (
    <>
      {/* <HomePage /> */}
      <HomeIndex />
    </>
  );
}

export default App;
