import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";

// Component
import MediumHeader from "../../templates/text/MediumHeader.js";

// Styles
const Container = styled.section`
    background-color: #101012;
    padding: 6rem 0;

    @media (max-width: 600px) {
        padding: 4rem 0;
    }
    @media (min-width: 601px) and (max-width: 1024px) {
        padding: 3rem 0;
    }
`;

const Wrapper = styled.div`
    width: 85%;
    margin: auto;

    @media (max-width: 600px) {
        width: 90%;
    }
`;

const HeaderBox = styled.div`
    text-align: center;
    margin-bottom: 4rem;
`;

const FAQBox = styled.div`
    background-color: #2b2015;
    border-radius: 1rem;
    padding: 1rem;
    margin: 2.5rem 0;
    overflow: hidden;

    @media (min-width: 601px) and (max-width: 1024px) {
        margin: 1.5rem 0;
    }
`;

const FAQText = styled.p`
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    font-family: "Montserrat";
    margin: 0;

    @media (max-width: 600px) {
        font-size: 1em;
    }
    @media (min-width: 601px) and (max-width: 1024px) {
        font-size: 1.1rem;
    }
`;

export default function FAQ() {
    const faqData = [
        // Add your FAQ data here
        {
            question: "What is Fafy Token?",
            answer: "Fafy Token is a cryptocurrency designed to revolutionize content monetization. It empowers creators by providing a transparent and fair ecosystem for distributing and monetizing digital content."
        },
        {
            question: "How does Fafy Token work?",
            answer: "Fafy Token operates on a blockchain-powered platform that enables direct engagement between content creators and their audience. It facilitates transactions, rewards engagement, and ensures fair compensation for creators."
        },
        {
            question: "What are the benefits of using Fafy Token",
            answer: "Using Fafy Token allows creators to monetize their content more transparently, receive fair compensation, engage directly with their audience, and access a decentralized platform that promotes innovation."
        },{
            question: "How can I acquire Fafy Tokens?",
            answer: "Fafy Tokens can typically be acquired through cryptocurrency exchanges where it's listed for trading or through participating in token sales organized by the Fafy Token platform."
        },{
            question: "What makes Fafy Token different from other cryptocurrencies?",
            answer: "Fafy Token is specifically tailored for content monetization, focusing on fair compensation for creators and fostering direct engagement between creators and users within its ecosystem."
        },{
            question: "Is Fafy Token secure?",
            answer: "Fafy Token operates on a blockchain, leveraging the security and transparency inherent in this technology. Additionally, it might undergo independent audits to ensure its security features."
        },{
            question: "How can I use Fafy Tokens within the platform?",
            answer: "Fafy Tokens can be used for transactions within the platform, purchasing content, accessing exclusive content, participating in governance mechanisms, and rewarding engagement."
        },{
            question: "Can anyone become a content creator on the Fafy Token platform?",
            answer: "Yes, the Fafy Token platform aims to provide opportunities for various content creators, allowing them to join and engage with their audience."
        },{
            question: "Where can I find more information about Fafy Token?",
            answer: "You can find more information on the official Fafy Token website, whitepaper, social media channels, or community forums associated with the project."
        },{
            question: "How is Fafy Token planning to grow and evolve?",
            answer: "Fafy Token typically follows a roadmap detailing its future plans for development, which might include expanding its user base, introducing new features, enhancing security, and fostering community growth."
        },
    ];

    return (
        <Container id="faq">
            <Wrapper>
                <HeaderBox>
                    <MediumHeader>FAQ</MediumHeader>
                </HeaderBox>

                {faqData.map((faq, index) => (
                    <FAQBox key={index}>
                        <Grid container>
                            <Grid item xs={12}>
                                <FAQText><strong>{index + 1}:</strong> {faq.question}</FAQText>
                                <FAQText>{faq.answer}</FAQText>
                            </Grid>
                        </Grid>
                    </FAQBox>
                ))}
            </Wrapper>
        </Container>
    );
}
