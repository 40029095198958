import React, { useCallback, useEffect, useState } from "react";
import { ethers, providers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

import RPCICOContract, { ICOcontractAbi } from "../contract/ico";
import { TokenContract } from "../contract/ERC20Contract";

// shared component
import NavBar from "../../shared/NavBar.js";
import Footer from "../../shared/Footer.js";

// component
import MainHeader from "./MainHeader.js";
import Hero from "./Hero.js";
import Features from "./Features.js";
import PreSale from "./PreSale.js";
import Tokenomic from "./Tokenomic.js";
import TokenSale from "./TokenSale.js";
import RoadMap from "./RoadMap.js";
import Faq from "./FAQ.js";
import {
	useWeb3Modal,
	useWeb3ModalAccount,
	useWeb3ModalProvider,
	useDisconnect,
	useWeb3ModalState,
} from "@web3modal/ethers5/react";

const polygonMainnet = {
	chainId: 56, // Polygon Mainnet chain ID
	rpcUrl: "https://bsc-dataseed1.binance.org/", // Replace with your Infura project ID
};

const providerOptions = {
	walletconnect: {
		package: WalletConnectProvider,
		options: {
			infuraId: "1aecdbb69033425ea6c39fbfc2619963", // Replace with your Infura project ID
		},
	},
	polygonMainnet: {
		package: WalletConnectProvider, // You can use the same package for Polygon Mainnet
		options: {
			rpc: {
				[polygonMainnet.chainId]: polygonMainnet.rpcUrl,
			},
		},
	},
};

var web3Modal;
if (typeof window !== "undefined") {
	web3Modal = new Web3Modal({
		network: "mainnet", // optional
		cacheProvider: false,
		providerOptions, // required
	});
}

export default function Index() {
	const [currencyValue, setCurrencyValue] = useState(0);
	const [currency, setCurrency] = useState("BNB");
	const [currencyBalance, setCurrencyBalance] = useState({
		Token: 0,
		BNB: 0,
	});
	const [userAddress, setUserAddress] = useState(null);
	const [buyLoader, setBuyLoader] = useState(false);
	const [webProvider, setWebProvider] = useState(null);
	const [chainId, setChainId] = useState(0);
	const [bnbRaised, setBnbRaised] = useState(0);
	const { open, close } = useWeb3Modal();
	const { walletProvider } = useWeb3ModalProvider();
	const { open: isOpen } = useWeb3ModalState();
	const { isConnected } = useWeb3ModalAccount();
	const { disconnect } = useDisconnect();
	const [referralAddress, setReferralAddress] = useState("");

	// disconnect()
	let ICOContract;
	if (webProvider) {
		ICOContract = new ethers.Contract(
			"0x5284B3C4A223eD200F8881915A00d431023DBd53",
			ICOcontractAbi,
			webProvider?.getSigner() || "",
		);
	}

	useEffect(() => {
		if (RPCICOContract) {
			getBNBRaised();
		}
	}, [RPCICOContract]);

	const handleDisconnect = useCallback(() => {
		disconnect();
		setUserAddress(null); // Reset the user address
		// Perform any other cleanup or state reset actions here
	}, [disconnect]);

	const getBNBRaised = async () => {
		if (chainId !== polygonMainnet.chainId && !RPCICOContract) {
			return;
		}

		try {
			const totalBNB = await RPCICOContract.totalBNBReceived();
			console.log("totalBNB", totalBNB);
			setBnbRaised(ethers.utils.formatEther(totalBNB));
		} catch (error) {
			console.error("Error fetching BNB raised:", error);
		}
	};

	useEffect(() => {
		(async () => {
			const web3Provider = new providers.Web3Provider(walletProvider);
			const signer = web3Provider.getSigner();
			const address = await signer.getAddress();
			const network = await web3Provider.getNetwork();

			// Check if connected network is Polygon Mainnet
			if (network.chainId !== polygonMainnet.chainId) {
				// Display a message asking the user to connect to Polygon Mainnet
				toast.warn("Please connect to BSC Mainnet");
				return;
			}

			setUserAddress(address);
			setWebProvider(web3Provider);
			setChainId(network.chainId);
		})();
	}, [walletProvider]);

	const connect = useCallback(async function () {
		open();
	}, []);

	useEffect(() => {
		// Assuming the URL is something like https://fafytoken.com/?referral=0x123...
		const queryParams = new URLSearchParams(window.location.search);
		const refAddress = queryParams.get("referral"); // 'referral' is the query parameter
		if (refAddress && ethers.utils.isAddress(refAddress)) {
			setReferralAddress(refAddress);
		}
	}, []);

	useEffect(() => {
		if (userAddress) {
			getBalance();
		}
	}, [userAddress]);

	// Auto connect to the cached provider
	useEffect(() => {
		if (web3Modal.cachedProvider) {
			connect();
		}
	}, [connect]);

	useEffect(() => {
		if (walletProvider?.on) {
			const handleAccountsChanged = (accounts) => {
				setUserAddress(accounts[0]);
			};

			const handleChainChanged = (_hexChainId) => {
				setChainId(parseInt(_hexChainId, 16));
				getBalance();
			};

			walletProvider.on("accountsChanged", handleAccountsChanged);
			walletProvider.on("chainChanged", handleChainChanged);

			// Subscription Cleanup
			return () => {
				if (walletProvider.removeListener) {
					walletProvider.removeListener(
						"accountsChanged",
						handleAccountsChanged,
					);
					walletProvider.removeListener("chainChanged", handleChainChanged);
				}
			};
		}
	}, [webProvider]);

	const getBalance = async () => {
		if (
			!userAddress ||
			!window.ethereum ||
			chainId !== polygonMainnet.chainId
		) {
			setCurrencyBalance({
				Token: 0,
				BNB: 0,
			});
			return;
		}

		try {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const balance = await provider.getBalance(userAddress);
			const bnbBalance = ethers.utils.formatEther(balance);
			let bump = [];
			bump.push(TokenContract.balanceOf(userAddress));
			// bump.push(
			//     TokenContract.balanceOf(
			//         '0xD9a1070f832Cc02ff9d839500EEf6E8c64983726'
			//     )
			// );
			bump.push(provider.getBalance(userAddress));
			let results = await Promise.all(bump);

			setCurrencyBalance({
				Token: parseFloat(ethers.utils.formatUnits(results[0]), 18),
				BNB: parseFloat(ethers.utils.formatUnits(results[1], 18)),
			});
		} catch (err) {
			setCurrencyBalance({
				Token: 0,
				BNB: 0,
			});
		}
	};

	// const handleBuy = async () => {
	//   setBuyLoader(true);
	//   if (!userAddress) {
	//     toast.warn("Please connect metamask");
	//     setBuyLoader(false);
	//     return;
	//   }
	//   if (chainId !== polygonMainnet.chainId) {
	//     toast.warn("Please use BSC Mainnet");
	//     setBuyLoader(false);
	//     return;
	//   }

	//   try {
	//     if (currencyValue < 0.05) {
	//       toast.error("The minimum Buy amount is 0.05 BNB");
	//       setBuyLoader(false);
	//       return;
	//     }
	//     if (currencyValue <= 0 || !currencyValue) {
	//       toast.warn("Please input currency amount");
	//       setBuyLoader(false);
	//       return;
	//     }

	//     if (currency === "BNB") {

	//       let buy = ICOContract.buyTokensWithBNB({
	//         value: ethers.utils.parseUnits(currencyValue),
	//       });
	//       await buy
	//       let waitFortx = await buy.wait();
	//       if (waitFortx) {

	//         toast.success("Buy Successful!");
	//         setBuyLoader(false);
	//       }
	//     }
	//   } catch (error) {
	//     if (error?.code === "ACTION_REJECTED") {
	//       toast.error("User Rejected The Transaction");
	//     } else {
	//       toast.error(error.message);
	//     }
	//     setBuyLoader(false);
	//   }
	// };

	const handleBuy = async () => {
		setBuyLoader(true);

		// Extract the referral address from the URL query parameter
		const searchParams = new URLSearchParams(window.location.search);
		const referralAddressFromUrl = searchParams.get("referral");

		// Check if the user has connected their wallet
		if (!userAddress) {
			toast.warn("Please connect metamask");
			setBuyLoader(false);
			return;
		}

		// Check if the connected network is correct
		if (chainId !== polygonMainnet.chainId) {
			toast.warn("Please switch to the BSC Mainnet");
			setBuyLoader(false);
			return;
		}

		// Check if the currency value is valid
		if (currencyValue < 0.05) {
			toast.error("The minimum buy amount is 0.05 BNB");
			setBuyLoader(false);
			return;
		}

		if (currencyValue <= 0 || !currencyValue) {
			toast.warn("Please input currency amount");
			setBuyLoader(false);
			return;
		}

		// Validate the referral address from URL
		let referralAddress = ethers.utils.isAddress(referralAddressFromUrl)
			? referralAddressFromUrl
			: ethers.constants.AddressZero;

		console.log("Referral Address:", referralAddress);

		try {
			const transactionOptions = {
				value: ethers.utils.parseUnits(currencyValue.toString(), "ether"),
			};

			// Call the smart contract function with the referral address and transaction options
			const transaction = await ICOContract.buyTokensWithBNB(
				referralAddress,
				transactionOptions,
			);
			const transactionReceipt = await transaction.wait();

			if (transactionReceipt.status) {
				toast.success("Buy successful!");
				// Additional steps if necessary, like updating the UI
			} else {
				toast.error("Transaction failed.");
			}
		} catch (error) {
			console.error("Transaction error:", error);
			toast.error(`Transaction failed: ${error.message}`);
		}

		setBuyLoader(false);
	};

	return (
		<main>
			<NavBar
				userAddress={userAddress}
				handleWalletConnect={connect}
				handleWalletDisconnect={handleDisconnect}
			/>
			<ToastContainer autoClose={3000} />
			<MainHeader />
			{/* Hero Section has the SWAP form */}
			<Hero
				buyLoader={buyLoader}
				handleBuy={handleBuy}
				userAddress={userAddress}
				currency={currency}
				setCurrency={setCurrency}
				currencyValue={currencyValue}
				setCurrencyValue={setCurrencyValue}
				currencyBalance={currencyBalance}
				bnbRaised={bnbRaised}
			/>
			<Features />
			<PreSale bnbRaised={bnbRaised} />
			<Tokenomic />
			<TokenSale />
			<RoadMap />
			<Faq />
			<Footer />
		</main>
	);
}
