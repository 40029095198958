import React from "react";
import styled from "styled-components";

const HeaderText = styled.h2`
	color: #fff;
	font-size: 3.3rem;
	font-weight: 700;
	font-family: "PlayBold";
	line-height: 1.2;
	text-transform: capitalize;

	@media (max-width: 600px) {
		font-size: 2.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.4rem;
	}
`;

export default function LargeHeader({ children }) {
	return <HeaderText>{children}</HeaderText>;
}
