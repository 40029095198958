import React from "react";
import styled from "styled-components";

const HeaderText = styled.div`
	color: #fff;
	font-size: 1rem;
	font-weight: 300;
	font-family: "Montserrat";
	opacity: 0.9;

	@media (max-width: 600px) {
		font-size: 1em;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
	}
`;

export default function LargeHeader({ children }) {
	return <HeaderText>{children}</HeaderText>;
}
